import { Container } from '../../../components/Container'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useUserDataStore } from '../../../stores/userStore'
import Logo from '../../../assets/img/riwis_logo_color.svg'
import { useCallback, useMemo } from 'react'

export const RiwisRegistrationIntro = ({ setHideRiwisIntro }) => {
  const [{ user, listAsPartner }, { setShowLoginModal, setListAsPartner }] = useUserDataStore()
  const location = useLocation()
  const history = useHistory()

  const cookieStorage = useMemo(() => {
    return {
      getItem: (key) => {
        const cookies = document.cookie
          .split(';')
          .map((cookie) => cookie.split('='))
          .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {})

        return cookies[key]
      },
      setItem: (key, value, maxAge) => {
        document.cookie = `${key}=${value};max-age=${maxAge}`
      },
    }
  }, [])

  const handleGoToSurvey = useCallback(() => {
    cookieStorage.setItem('hideRiwisIntro2023', true, 3600)
    if (setHideRiwisIntro) {
      setHideRiwisIntro(true)
    }
    if (location.pathname === '/registration/riwis/intro') {
      history.push('/survey/riwis-marktdatenerhebung-2023')
    }
  }, [location, history, cookieStorage, setHideRiwisIntro])

  // useEffect(() => {
  //   if (
  //     location.pathname.includes('/survey/riwis-marktdatenerhebung-2022') &&
  //     !cookieStorage.getItem('hideRiwisIntro')
  //   ) {
  //     cookieStorage.setItem('hideRiwisIntro', true, 1)
  //   }
  // }, [location, cookieStorage])

  return (
    <Container>
      <div className="intro riwis">
        {/* <h2 className="display-5 text-primary">RIWIS</h2> */}
        <img width="125" src={Logo} alt="Riwis Logo" />
        <h1 className="display-4">Marktdaten&shy;befragung</h1>
        <section>
          <h3 className="mb-4 riwis-text-secondary" style={{ fontWeight: 'bold' }}>
            Wie sieht die neue Normalität auf dem Immmobilienmarkt aus? - Einladung zur Befragung
            <br />
          </h3>
          <p className="lead">
            bulwiengesa ist seit fast 40 Jahren als unabhängiges Analysehaus in der Immobilienmarktforschung
            tätig. Für die Datensammlung und Marktbeobachtung bauen wir wie kein anderes Unternehmen auch auf
            die Expertise lokaler Expertinnen und Experten. Für mehr als 125 Städte in Deutschland analysieren
            wir die Segmente Wohnen, Büro, Einzelhandel und Industrie.
          </p>
          <p className="lead">
            Niedrige Bautätigkeit, hohe Baukosten, gestiegene Zinsen, geringes Investmentvolumen, neues
            Heizungsgesetz – dieses ist nur eine kleine Auswahl der wirtschaftlichen und politischen
            Schlagworte des laufenden Jahres. Doch reagieren Assetklassen und regionale Märkte einheitlich auf
            die vielen Herausforderungen im Immobilienmarkt? Wir glauben, dass es durchaus unterschiedliche
            Nuancen im vielschichtigen deutschen Immobilienmarkt gibt. Helfen Sie uns die regionalen
            Besonderheiten Ihres Immobilienmarktes aufzunehmen. Lassen Sie uns eine möglichst fundierte
            Datenbasis zur Beschreibung des Status Quo für das Jahr 2023 ableiten, um mit Zuversicht
            Lösungsansätze zu entwickeln.
          </p>
          <p className="lead">
            Wir bitten Sie daher herzlich, an der Befragung teilzunehmen. Um nachhaltige Strukturen und neue
            Trends zu erkennen, sind jegliche Informationen zu den Märkten für uns von großem Interesse.
          </p>
        </section>
        <section>
          <h3 className="text-secondary mb-4">Warum sollten Sie an der Umfrage teilnehmen?</h3>
          <ul className="lead">
            <li>
              Sie erhalten bis Herbst 2024 einen kostenfreien Zugang zu unserer umfangreichen Datenbank RIWIS
              für all jene Städte, für die Sie einen Fragebogen ausgefüllt haben. Damit können Sie auf das
              Business-Paket der umfangreichsten Immobilienmarktdatenbank Deutschlands zugreifen. Dies umfasst
              Marktdaten für die Nutzungsarten Wohnen, Büro, Handel und Industrie. Ergänzend erhalten Sie
              unseren bekannten Marktreport als PDF übermittelt.
              <div style={{ fontStyle: 'italic', lineHeight: '190%' }}>
                Die Teilnahme ist auf max. 5 Städte begrenzt. Mehrfachanmeldungen können nicht gewertet
                werden. Nach erfolgter Teilnahme und Abschluss der Marktdatenerhebung wird Ihnen unter
                Vorbehalt Zugriff auf den selektierten Leistungsumfang von RIWIS Online gewährt. Hierzu senden
                wir Ihnen im Dezember einen Aktivierungslinks und die geltenden Nutzungsbedingungen zu.
              </div>
            </li>
            <li>
              Auf Wunsch listen wir Sie als{' '}
              <Link to={{ pathname: 'https://www.riwis.de/intro#market-experts' }} target="_blank">
                Datenpartner
              </Link>{' '}
              in unserer RIWIS Applikation auf.
            </li>
            <li>
              Sie tragen wesentlich dazu bei, die Markttransparenz am deutschen Immobilienmarkt zu erhöhen.
              Als Teilnehmer erhalten Sie nach Abschluss der Erhebung unser Logo zum{' '}
              <Link
                to={{ pathname: 'https://bulwiengesa.de/de/magazin/datentauscher-werden' }}
                target="_blank"
              >
                Transparenz-Netzwerk
              </Link>{' '}
              und dürfen sich als Mitglied unseres Transparenz-Netzwerks ausweisen.
            </li>
          </ul>
        </section>

        <section>
          <h3 className="text-secondary mb-4">Wie funktioniert die Umfrage?</h3>
          <p className="lead">
            Wählen Sie <b>bis zu fünf Städte</b> aus, für die Sie Ihre Einschätzungen abgeben möchten. Unser
            neuer Online-Fragebogen unterteilt Fragen zu den Assetklassen Wohnen, Büro, Einzelhandel und
            Industrie. Als Orientierungshilfe zeigen wir Ihnen die Marktdaten des Vorjahres an sowie bestimmte
            Lagekriterien.
          </p>
          <ul className="lead">
            <li>Start der Befragung: ab sofort</li>
            <li>Ende der Befragung: Mittwoch, 15.11.2023</li>
          </ul>
        </section>

        <section>
          <h3 className="text-secondary mb-4">Sie haben Fragen?</h3>
          <p className="lead">
            Informationen zum Datenschutz im Rahmen der Befragung stellen wir gerne{' '}
            <Link
              to={{ pathname: 'https://www2.bulwiengesa.de/mde/pdf/datenschutzhinweis.pdf' }}
              target="_blank"
            >
              hier
            </Link>{' '}
            bereit.
            <br /> Haben Sie Fragen oder Anregungen zu unserer Erhebung, senden Sie uns gerne eine Nachricht
            an Felix Embacher MRICS und Jan Finke.
          </p>
          <p className="lead">
            Sie erreichen uns unter{' '}
            <Link to={{ pathname: 'mailto:riwis@bulwiengesa.de' }} target="_blank">
              riwis@bulwiengesa.de
            </Link>
            , Tel: 0201-87469663
          </p>
          <p className="lead">
            Mit freundlichen Grüßen,
            <br />
            bulwiengesa AG
          </p>
        </section>

        <div className="d-flex w-100 justify-content-center">
          {!user ? (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Link to="/registration/riwis/formular">
                <button className="riwis-btn riwis-primary" style={{ fontSize: '1rem', fontWeight: '500' }}>
                  Weiter zur Registrierung
                </button>
              </Link>
              <div className="my-5" style={{ fontSize: '1rem' }}>
                <strong>ODER</strong>
              </div>
              <button
                className="riwis-btn riwis-white"
                style={{ fontSize: '1rem', fontWeight: '500' }}
                onClick={() => setShowLoginModal(true)}
              >
                Anmeldung
              </button>
            </div>
          ) : (
            <div className="d-flex flex-column">
              <div className="custom-control custom-checkbox mb-5">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  name="list_as_partner"
                  id="list_as_partner"
                  value={listAsPartner !== null ? listAsPartner : user.list_as_partner}
                  checked={listAsPartner !== null ? listAsPartner : user.list_as_partner}
                  onChange={(e) => setListAsPartner(e.target.checked)}
                ></input>
                <label
                  className="custom-control-label"
                  style={{ fontWeight: '500', fontSize: '0.875rem' }}
                  htmlFor="list_as_partner"
                >
                  Ja ich möchte als Datenpartner auf der Website{' '}
                  <Link to={{ pathname: 'https://www.riwis.de' }} target="_blank">
                    www.riwis.de
                  </Link>{' '}
                  als Marktexpert*in aufgelistet werden
                </label>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="riwis-btn riwis-primary"
                  style={{ fontSize: '1rem', fontWeight: '500' }}
                  onClick={handleGoToSurvey}
                >
                  Zur Umfrage
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}
