import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useCallback } from 'react'
import { numberSeparator } from './mapHelper'

export const DataCard = ({
  riwisValue,
  valueRange,
  unit,
  withDecimal,
  userValue,
  year,
  handleUserInput,
  averageChange,
  averagePercentage,
}) => {
  const [showMore, setShowMore] = useState(false)

  const handleFocus = useCallback((event) => {
    if (event.target.textContent === '--') {
      event.target.textContent = ''
    }
  }, [])

  const handleBlur = useCallback(
    (event) => {
      const valueString = event.target.textContent.replace(/,/g, '.')
      let value = parseFloat(valueString)
      if (value > valueRange.max) {
        event.target.textContent = numberSeparator(valueRange.max, withDecimal ? 1 : 0, withDecimal)
      } else if (value < valueRange.min) {
        event.target.textContent = numberSeparator(valueRange.min, withDecimal ? 1 : 0, withDecimal)
      }
      if (event.target.textContent.trim() === '' || isNaN(parseFloat(event.target.textContent.trim()))) {
        event.target.textContent = '--'
        handleUserInput(null)
      } else {
        handleUserInput(value)
      }
    },
    [valueRange, handleUserInput, withDecimal]
  )

  const onKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.target.blur()
    }
  }, [])

  return (
    <div className="cardComparison">
      {riwisValue === null ? (
        <div className="cardRow">
          <div className="cardCell riwisNoData">Für diese Stadt gibt es keine Daten zu dieser Frage!</div>
        </div>
      ) : (
        <>
          <div className="cardRow">
            <div className="cardCell">
              <div className="cellHeader">
                <b style={{ color: '#00697C' }}>RIWIS</b> Wert {year}
              </div>
            </div>
            <div className="breaker"></div>
            <div className="cardCell">
              <div className="cellHeader">
                <b style={{ color: '#002940' }}>Mein Wert {year + 1}</b>
              </div>
            </div>
          </div>
          <div className="cardRow">
            <div className="cardCell">
              <h4 style={{ color: '#00697C', fontWeight: 700 }}>
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <div className="riwisValue mr-3">{numberSeparator(riwisValue, withDecimal ? 1 : 0)} </div>
                  <div>{unit}</div>
                </div>
              </h4>
            </div>
            <div className="breaker"></div>
            <div className="cardCell">
              <h4 style={{ color: '#002940', fontWeight: 700 }}>
                <div className="d-flex flex-wrap align-items-center justify-content-center">
                  <span
                    contentEditable
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    suppressContentEditableWarning={true}
                    className="riwisDataInput mr-0 mr-sm-3"
                    onKeyPress={onKeyPress}
                  >
                    {isNaN(userValue) || userValue === null
                      ? '--'
                      : numberSeparator(
                          userValue >= 0 ? userValue : riwisValue,
                          withDecimal ? 1 : 0,
                          withDecimal
                        )}
                  </span>{' '}
                  <div>{unit}</div>
                </div>
              </h4>
            </div>
          </div>
          {showMore === true && (
            <>
              <div className="cardRow additionalStats">
                <div className="cardCell">
                  <div>letzte 5 Jahre:</div>
                </div>
                <div className="breaker"></div>
                <div className="cardCell">
                  <div>zum Vorjahr:</div>
                </div>
              </div>

              <div className="cardRow additionalStats">
                <div className="cardCell" style={{ paddingTop: 0 }}>
                  <div style={{ textAlign: 'center' }}>
                    Ø{' '}
                    <b>
                      {averageChange > 0 && '+'}
                      {numberSeparator(averageChange, 1)}
                    </b>{' '}
                    {unit} <span style={{ fontSize: '0.875rem' }}>p.a.</span>
                  </div>
                </div>
                <div className="breaker"></div>
                <div className="cardCell" style={{ paddingTop: 0 }}>
                  <div style={{ textAlign: 'center' }}>
                    <span className={'font-weight-bold'}>
                      {userValue > riwisValue && '+'}
                      {userValue === null
                        ? '--'
                        : numberSeparator(
                            (userValue >= 0 ? userValue : riwisValue) - riwisValue,
                            withDecimal ? 1 : 0
                          )}
                    </span>{' '}
                    {unit}
                  </div>
                </div>
              </div>

              <div className="cardRow additionalStats">
                <div className="cardCell" style={{ paddingTop: 0 }}>
                  {averagePercentage !== null && (
                    <div style={{ textAlign: 'center' }}>
                      Ø{' '}
                      <b>
                        {averagePercentage > 0 && '+'}
                        {numberSeparator(averagePercentage, 1)}
                      </b>{' '}
                      % <span style={{ fontSize: '0.875rem' }}>p.a.</span>
                    </div>
                  )}
                </div>
                <div className="breaker"></div>
                <div className="cardCell" style={{ paddingTop: 0 }}>
                  {averagePercentage !== null && (
                    <div style={{ textAlign: 'center' }}>
                      <span className="font-weight-bold">
                        {userValue > riwisValue && '+'}
                        {userValue === null
                          ? '--'
                          : numberSeparator(
                              ((userValue >= 0 ? userValue : riwisValue) / riwisValue - 1) * 100,
                              withDecimal ? 1 : 0
                            )}
                      </span>{' '}
                      %
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="cardRow">
            <div className="cardCell" style={{ paddingTop: 0 }}>
              <div onClick={() => setShowMore(!showMore)} className="riwisShowMore">
                {!showMore ? (
                  <span>
                    mehr anzeigen <FontAwesomeIcon icon={faCaretDown} />
                  </span>
                ) : (
                  <span>
                    weniger anzeigen <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                )}
              </div>
            </div>
            <div className="breaker"></div>
            <div className="cardCell">
              <div className="riwisShowMore"></div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
